import React, { useState } from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from '../../components/Loader';
import { authData } from "../../components/getAuth";
import { Controller, useForm } from "react-hook-form";
import moment from 'moment';
import Select from "react-select";

export default function ViewComplaintPopup({ setViewComplaintModalShow, bookingId, addNewComplaint }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = useState(false);
  const [complaintData, setComplaintData] = useState(null);

  const { register, handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onChange'
  });

  React.useEffect(() => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/get-complaint/' + bookingId, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        setLoading(false);
        setComplaintData(response.data.data);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }, [API_TOKEN, bookingId]);

  const onSubmitLocal = (data) => {

    let postData = { comment: data?.comment, status: data?.status?.value };
    setViewComplaintModalShow(false);
    setLoading(true);

    axios.post(process.env.REACT_APP_API_URL + 'v1/add-complaint-comment/' + complaintData?.id, postData, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        addNewComplaint(response.data.message, response.data.data);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader />}

    <div className={`${styles.FullDiv}`}>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Complaint Id:</label>
        <div>{complaintData?.complaint_id}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Booking Id:</label>
        <div>{complaintData?.booking?.reference_id}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Customer Name:</label>
        <div>{complaintData?.booking?.customer_name}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Complaint Source:</label>
        <div>{complaintData?.complaint_source}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Complaint Reason:</label>
        <div>{complaintData?.complaint_reason}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Complaint Priority:</label>
        <div>{complaintData?.priority}</div>
      </div>
      <div className={`${styles.FormGroupFull}`} style={{ width: '50%', display: 'inline-block' }}>
        <label className={`${styles.FormLabel}`}>Complaint Lodging Date:</label>
        <div>{moment(complaintData?.lodging_date).format('DD/MM/YYYY')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <p className={`${styles.FullTitle}`}>Add Comment</p>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Comment*</label>
          <textarea className={`${styles.FormControlTextArea}`} {...register("comment", { required: 'This field should be required' })}></textarea>
          {errors.comment && <span className={`${styles.ErrorM}`}>{errors.comment.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Comaplint Status*</label>
          <Controller
            control={control}
            {...register('status', { required: 'Please select comaplint status' })}
            options={[{ value: 1, label: "Open" }, { value: 2, label: "Close" }]}
            render={({ field }) => (
              <Select
                {...field}
                options={[{ value: 1, label: "Open" }, { value: 2, label: "Close" }]}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors.status && <span className={`${styles.ErrorM}`}>{errors.status.message}</span>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="Cancel" type="button" onClick={(e) => setViewComplaintModalShow(false)} />
          <input className={`${styles.SubmitBU}`} value="Add" type="submit" />
        </div>
      </form>
    </div>

    {(complaintData?.comments?.length > 0) && <div className={`${styles.MondalBodyDeRow2}`}>
      <p className={`${styles.FullTitle}`}>Complaint Details</p>
      <div className={`${styles.ModalDeFullSec}`}>
        <div className={`${styles.TableSec}`}>
          <table>
            <tr>
              <td className={`${styles.TableHead}`} style={{ background: '#E64F22', color: '#ffffff' }}>Interaction Date</td>
              <td className={`${styles.TableHead}`} width={'60%'} style={{ background: '#E64F22', color: '#ffffff' }}>Comments</td>
              <td className={`${styles.TableHead}`} style={{ background: '#E64F22', color: '#ffffff' }}>Posted By</td>
              <td className={`${styles.TableHead}`} style={{ background: '#E64F22', color: '#ffffff' }}>Status</td>
            </tr>
            {complaintData?.comments?.map((item, index) => {
              return (<tr key={index}>
                <td className={`${styles.TableCon}`}>{moment(item?.added_date).format('DD/MM/YYYY')}</td>
                <td className={`${styles.TableCon}`}>{item.comment}</td>
                <td className={`${styles.TableCon}`}>{item.added_by_user}</td>
                <td className={`${styles.TableCon}`}>{item.status === 2 ? 'Close' : 'Open'}</td>
              </tr>);
            })}
          </table>
        </div>
      </div>
    </div>}
  </React.Fragment>)
}