import React, { useEffect } from 'react';
import styles from './list.module.css';
import axios from 'axios';
import Loader from '../../components/Loader';
import { authData } from "../../components/getAuth";
import { Controller, useForm } from "react-hook-form";
import moment from 'moment';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddComplaintPopup({ setAddComplaintModalShow, bookingData, addNewComplaint }) {
  const auth = authData();
  const API_TOKEN = auth?.api_token;
  const [loading, setLoading] = React.useState(false);
  /*
  const [bookingData, setBookingData] = React.useState({});

  

  React.useEffect(() => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/bookings/' + bookingId, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        setLoading(false);
        let bookingDataTemp = response.data.data;
        setBookingData(bookingDataTemp);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }, [API_TOKEN, bookingId]);

  const onSubmitLocal = (data) => {
    let postData = { amount: data?.amount, note: data?.note, payment_method: data?.payment_method?.value };
    setAddComplaintModalShow(false);
    setLoading(true);
    data = { ...data, payment_status: 1 };

    axios.post(process.env.REACT_APP_API_URL + 'v1/add-payment/' + bookingId, postData, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        paymentItem(response.data.message, response.data.data);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }*/

  const { register, handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      booking_id: { value: bookingData?.id, label: bookingData?.reference_id },
      customer_name: bookingData?.customer_name
    }
  });

  useEffect(() => {
    console.log('bookingData');
    console.log(bookingData);
    console.log(bookingData);
  }, [bookingData]);

  const onSubmitLocal = (data) => {
    data = { ...data, booking_id: data?.booking_id?.value, priority: data?.priority?.value, lodging_date: moment(data?.lodging_date).format('YYYY-MM-DD') };

    setAddComplaintModalShow(false);
    setLoading(true);

    axios.post(process.env.REACT_APP_API_URL + 'v1/add-complaint/' + bookingData?.id, data, {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        addNewComplaint(response.data.message, response.data.data);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }


  return (<React.Fragment>
    {loading && <Loader />}

    <div className={`${styles.FullDiv}`}>
      <form onSubmit={handleSubmit(onSubmitLocal)}>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Booking Id*</label>
          <Controller
            control={control}
            {...register('booking_id', { required: 'Please select bookings' })}
            options={[{ value: bookingData?.id, label: bookingData?.reference_id }]}
            render={({ field }) => (
              <Select
                {...field}
                options={[{ value: bookingData?.id, label: bookingData?.reference_id }]}
              />
            )}
          />
          {errors.booking_id && <span className={`${styles.ErrorM}`}>{errors.booking_id.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Customer Name*</label>
          <input type="text" className={`${styles.FormControlTextArea}`} style={{ height: '40px' }} readOnly {...register("customer_name", { required: 'Please enter customer_name' })} />
          {errors.customer_name && <span className={`${styles.ErrorM}`}>{errors.customer_name.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Complaint Source*</label>
          <input type="text" className={`${styles.FormControlTextArea}`} style={{ height: '40px' }} {...register("complaint_source", { required: 'This field should be required' })} />
          {errors.complaint_source && <span className={`${styles.ErrorM}`}>{errors.complaint_source.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Complaint Reason*</label>
          <input type="text" className={`${styles.FormControlTextArea}`} style={{ height: '40px' }} {...register("complaint_reason", { required: 'This field should be required' })} />
          {errors.complaint_reason && <span className={`${styles.ErrorM}`}>{errors.complaint_reason.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Complaint Priority*</label>
          <Controller
            control={control}
            {...register('priority', { required: 'This field should be required' })}
            options={[{ value: 'High', label: 'High' }, { value: 'Medium', label: 'Medium' }, { value: 'Low', label: 'Low' }]}
            render={({ field }) => (
              <Select
                {...field}
                options={[{ value: 'High', label: 'High' }, { value: 'Medium', label: 'Medium' }, { value: 'Low', label: 'Low' }]}
              />
            )}
          />
          {errors.priority && <span className={`${styles.ErrorM}`}>{errors.priority.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Complaint Lodging Date*</label>
          <Controller
            control={control}
            {...register("lodging_date", { required: "This field should be required" })}
            render={({ field }) => (
              <DatePicker
                {...field}
                autoComplete="off"
                selected={field.value}
                className={`${styles.FormControlTextArea2}`}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => field.onChange(date)}
                ref={(ref) => {
                  field.ref({
                    focus: ref?.setFocus,
                  });
                }}
              />
            )}
          />
          {errors.lodging_date && <span className={`${styles.ErrorM}`}>{errors.lodging_date.message}</span>}
        </div>
        <div className={`${styles.FormGroupFull}`}>
          <label className={`${styles.FormLabel}`}>Comment*</label>
          <textarea className={`${styles.FormControlTextArea}`} {...register("comment", { required: 'This field should be required' })}></textarea>
          {errors.comment && <span className={`${styles.ErrorM}`}>{errors.comment.message}</span>}
        </div>
        <div className={`${styles.FormButtonArea}`}>
          <input className={`${styles.CancelBU}`} value="Cancel" type="button" onClick={(e) => setAddComplaintModalShow(false)} />
          <input className={`${styles.SubmitBU}`} value="Add" type="submit" />
        </div>
      </form>
    </div>


  </React.Fragment>)
}