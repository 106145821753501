import React, { useEffect, useRef, useState } from 'react';
import styles from './list.module.css';
import { TextField, Button, Menu, Tooltip, Select, MenuItem, Breadcrumbs, Typography } from '@mui/material';
import DataTable from 'react-data-table-component';
import { X, Filter, Eye, MapPin, Printer, Trash2, Mail } from 'react-feather';
import axios from 'axios';
import { TableLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import CancelPopup from "./CancelPopup";
import PaymentPopup from "./PaymentPopup";
import DetailsPopup from "./DetailsPopup";
import { Link } from 'react-router-dom';
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import download from 'downloadjs';
import { ComponentToPrintList } from './ComponentToPrintList';
import { useReactToPrint } from 'react-to-print';
import { DateRangePicker } from 'rsuite';
import PaymentCancelPopup from './PaymentCancelPopup';
import { authData } from '../../components/getAuth';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CustomTablePagination from '../../components/CustomTablePagination';
import AddComplaintPopup from './AddComplaintPopup';
import ViewComplaintPopup from './ViewComplaintPopup';

const brandColor = '#cccccc';
const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused
      ? brandColor
      : base.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? brandColor
        : base.borderColor,
    }
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#BFBFBF',
      marginLeft: '10px'
    }
  }
};

const convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  //const keys = Object.keys(props.data[0]);
  const keys = ['booking_id', 'customer_name', 'order_date', 'total_value', 'paid_amount', 'order_status', 'pickup_post_code', 'pickup_date', 'created_user', 'registered_or_guest_user', 'complaint_status'];

  result = '';
  //result += keys.join(columnDelimiter);
  keys.forEach(key => {
    if (key === 'booking_id') {
      result += 'Booking Id';
    } else if (key === 'customer_name') {
      result += 'Customer Name';
    } else if (key === 'order_date') {
      result += 'Order Date';
    } else if (key === 'total_value') {
      result += 'Total Value';
    } else if (key === 'paid_amount') {
      result += 'Paid Amount';
    } else if (key === 'order_status') {
      result += 'Order Status';
    } else if (key === 'pickup_post_code') {
      result += 'Pickup Post Code';
    } else if (key === 'pickup_date') {
      result += 'Pickup Date';
    } else if (key === 'created_user') {
      result += 'Created User';
    } else if (key === 'registered_or_guest_user') {
      result += 'Registered Or Guest User';
    } else if (key === 'complaint_status') {
      result += 'Complaint Status';
    } else {
      result += key;
    }
    result += columnDelimiter;
  });
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function FilterMenu({ filterBooking }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [defaultStatus, setDefaultStatus] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyFilter = () => {

    let status = '';
    if (defaultStatus || defaultStatus === 0) {
      status = defaultStatus;
    }

    filterBooking({ status: status });
    setAnchorEl(null);
  }

  const resetFilter = () => {
    setDefaultStatus(null);
    filterBooking({ status: '', customer_id: '' });
    setAnchorEl(null);
  }

  const handleChange = (event) => {
    setDefaultStatus(event.target.value);
  };

  return (<>
    <Button disableElevation startIcon={<FilterAltIcon />} onClick={handleClick} className="TableAddBU"><Filter />Filter</Button>
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <div className={`${styles.FilterSec}`}>
        <div className={`${styles.FilterCard}`}>
          <label>Status:</label>
          <Select styles={customStyles} onChange={handleChange} value={defaultStatus}>
            <MenuItem value={-1}>Order Cancelled - UK</MenuItem>
            <MenuItem value={0}>Order Booked - UK</MenuItem>
            <MenuItem value={1}>Item Assigned To Driver - UK</MenuItem>
            <MenuItem value={2}>Item Picked Up - UK</MenuItem>
            <MenuItem value={3}>Item In Warehouse - UK</MenuItem>
            <MenuItem value={4}>Item Shipped From Warehouse - UK</MenuItem>
            <MenuItem value={5}>Item Received In Warehouse - Ghana</MenuItem>
            <MenuItem value={6}>Item Out For Delivery - Ghana</MenuItem>
            <MenuItem value={7}>Delivered - Ghana</MenuItem>
          </Select>
        </div>
        <div className={`${styles.FilterBuArea}`}>
          <button type='button' onClick={resetFilter.bind(this)}>Reset</button>
          <button type='button' onClick={applyFilter.bind(this)}>Apply</button>
        </div>
      </div>
    </Menu>
  </>)
}

const BookingList = () => {
  const auth = authData();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [paymentModalShow2, setPaymentModalShow2] = useState(false);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [addComplaintModalShow, setAddComplaintModalShow] = useState(false);
  const [viewComplaintModalShow, setViewComplaintModalShow] = useState(false);
  const [addComplaintData, setAddComplaintData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [currentItemStr, setCurrentItemStr] = useState('');
  const [filterData, setFilterData] = useState({ status: '', start_date: '', end_date: '' });
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const componentRef = useRef();

  const columns = [{
    name: 'Booking Id',
    selector: row => row.reference_id,
    sortable: true,
    width: '5%'
  }, {
    name: 'Customer Name',
    selector: row => row.customer_name,
    sortable: true,
    width: '8%'
  }, {
    name: 'Order Date',
    selector: row => moment(row.created_at).format('DD/MM/YYYY LT'),
    sortable: true,
    width: '8%'
  }, {
    name: 'Total Value',
    cell: tableProps => <>&pound; {parseFloat(tableProps.total).toFixed(2)}</>,
    sortable: true,
    width: '8%'
  }, {
    name: 'Paid Amount',
    cell: tableProps => <>&pound; {parseFloat(tableProps.paid_amount).toFixed(2)}</>,
    sortable: true,
    width: '8%'
  }, {
    name: 'Due Balance',
    cell: tableProps => <>&pound; {(parseFloat(tableProps.total) - parseFloat(tableProps.paid_amount)).toFixed(2)}</>,
    sortable: true,
    width: '8%'
  }, {
    name: 'Order Status',
    selector: tableProps => (<>
      {(tableProps?.status === -1 || tableProps?.status === '-1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`}>Cancel</Button> : ((tableProps?.payment_status === 1 || tableProps?.payment_status === '1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`} onClick={(e) => { setPaymentModalShow2(true); setCurrentItem(tableProps?.id); }}>Paid</Button> : <Button className={`${styles.StatusBU}`} onClick={(e) => { setPaymentModalShow(true); setCurrentItem(tableProps?.id); }}>Unpaid</Button>)}
    </>),
    sortable: true,
    width: '10%'
  }, {
    name: 'Pickup Post Code',
    selector: row => row.post_code,
    sortable: true,
    width: '8%'
  }, {
    name: 'Pickup Date',
    selector: row => moment(row.pickup_date).format('DD/MM/YYYY'),
    sortable: true,
    width: '9%'
  }, {
    name: 'Created User',
    selector: row => row.created_user,
    sortable: true,
    width: '8%'
  }, {
    name: 'Complaint Status',
    cell: tableProps => (<>
      {tableProps?.complaint_status === 0 && <div style={{ textAlign: 'center' }}>
        <p style={{ marginBottom: '4px' }}>No Complaints</p>
        <button onClick={(e) => { setAddComplaintModalShow(true); setAddComplaintData(tableProps); }} style={{ background: 'none', color: '#e64f22', textDecoration: 'underline', fontWeight: 'bold' }}>Add</button>
      </div>}
      {tableProps?.complaint_status === 1 && <Button onClick={(e) => { setViewComplaintModalShow(true); setAddComplaintData(tableProps); }} className={`${styles.StatusBU}`} style={{ padding: '2px 0 !important' }}>Open</Button>}
      {tableProps?.complaint_status === 2 && <Button onClick={(e) => { setViewComplaintModalShow(true); setAddComplaintData(tableProps); }} className={`${styles.StatusBU} ${styles.DisaBU}`} style={{ padding: '2px 0 !important' }}>Close</Button>}
    </>),
    sortable: true,
    width: '8%'
  }, {
    name: 'Action',
    cell: tableProps => (
      <div className={(tableProps?.status === -1 || tableProps?.status === '-1') ? `${styles.ActionDiv} ${styles.ActionDivCancel}` : `${styles.ActionDiv}`}>
        <Tooltip title="View"><Button onClick={(e) => { setDetailsModalShow(true); setCurrentItem(tableProps?.id); setCurrentItemStr(tableProps?.reference_id); }}><Eye /></Button></Tooltip>
        <Tooltip title="Send Mail"><Button onClick={(e) => { sendMailBooking(tableProps?.id); }}><Mail /></Button></Tooltip>
        <Tooltip title="Print"><Button onClick={(e) => downloadItemList(tableProps?.id)}><Printer /></Button></Tooltip>
        {(tableProps?.status === -1 || tableProps?.status === '-1') ? <Tooltip title="Delete this booking"><Button onClick={(e) => { setDeleteModalShow(true); setCurrentItem(tableProps?.id); }}><Trash2 /></Button></Tooltip> : <Tooltip title="Cancel or keep this booking"><Button onClick={(e) => { setModalShow(true); setCurrentItem(tableProps?.id); }}><X /></Button></Tooltip>}
        <Tooltip title="Equiry your order"><Link as={Button} to={'/admin/booking/enquery/' + tableProps?.reference_id}><MapPin /></Link></Tooltip>
        {/*<Tooltip title="Request for certificate"><Button><ExternalLink /></Button></Tooltip>*/}
      </div>),
    sortable: false,
    width: '12%'
  }];

  const customDataTableStyles = {
    head: {
      style: {
        zIndex: 0
      },
    },
    headCells: {
      style: {
        backgroundColor: '#1e1e2d',
        color: '#fff'
      },
    },
    rows: {
      style: {
        backgroundColor: '#FFF',
      },
    },
  }

  const conditionalRowStyles = [{
    when: row => (row?.status === -1 || row?.status === '-1'),
    style: row => ({ backgroundColor: 'rgba(255,0,0,0.7)' }),
  }, {
    when: row => (row?.status === 1 || row?.status === '1'),
    style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.1)' }),
  }, {
    when: row => (row?.status === 2 || row?.status === '2'),
    style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.4)' }),
  }, {
    when: row => (row?.status === 3 || row?.status === '3'),
    style: row => ({ backgroundColor: 'rgba(230, 79, 34, 0.7)' }),
  }, {
    when: row => (row?.status === 4 || row?.status === '4'),
    style: row => ({ backgroundColor: 'rgba(42, 146, 245, 0.4)' }),
  }, {
    when: row => (row?.status === 5 || row?.status === '5'),
    style: row => ({ backgroundColor: 'rgba(42, 146, 245, 0.7)' }),
  }, {
    when: row => (row?.status === 6 || row?.status === '6'),
    style: row => ({ backgroundColor: 'rgba(5, 156, 42, 0.5)' }),
  }, {
    when: row => (row?.status === 7 || row?.status === '7'),
    style: row => ({ backgroundColor: 'rgba(5, 156, 42, 0.9)' }),
  }];

  const addNewComplaint = (message, item) => {
    toast.success(message);

    setData(prev => {
      let tempList = prev.map(i => {
        if (i.id === item.id)
          return { ...i, complaint_status: item.complaint_status };
        return i;
      });
      return tempList;
    });
    setLoading(false);
  }

  const downloadItemList = (id) => {
    axios(process.env.REACT_APP_API_URL + 'v1/bookings-item-pdf/' + id, {
      responseType: 'blob'
    }).then(response => {
      const content = response.headers['content-type'];
      download(response.data, 'download.pdf', content)
    });
  }

  const changeDate = (e) => {
    if (e) {
      setFilterData(prev => {
        return { ...prev, start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
      });
    } else {
      setFilterData(prev => {
        return { ...prev, start_date: '', end_date: '' };
      });
    }
  }

  const filterBooking = (data) => {
    setFilterData(prev => {
      return { ...prev, ...data };
    });
  }

  const deleteBooking = () => {
    setLoading(true);
    setDeleteModalShow(false);

    axios.delete(process.env.REACT_APP_API_URL + 'v1/bookings/' + currentItem, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      setData(prev => {
        let tempList = prev.filter(i => i?.id !== currentItem);
        return tempList;
      });
      setCurrentItem(0);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });
  }

  const paymentItem = (message, item) => {
    toast.success(message);

    setData(prev => {
      let tempList = prev.map(i => {
        if (i.id === item.id)
          return item;
        return i;
      });
      return tempList;
    });
    setLoading(false);
  }

  const paymentItem2 = (message, item) => {
    toast.success(message);

    setData(prev => {
      let tempList = prev.map(i => {
        if (i.id === item.id)
          return item;
        return i;
      });
      return tempList;
    });
    setLoading(false);
  }

  const camcelItem = (message, id) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    setData(prev => {
      let tempList = prev.map(i => {
        if (i.id === id)
          return { ...i, status: -1 }
        return i;
      });
      return tempList;
    });
    setLoading(false);
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery('page=' + page + '&perPage=' + perPage + '&search=' + filterText + '&start=' + filterData?.start_date + '&end=' + filterData?.end_date + '&status=' + filterData?.status);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [page, perPage, filterText, filterData]);

  useEffect(() => {
    if (debouncedQuery) {
      setLoading(true);
      axios(process.env.REACT_APP_API_URL + 'v1/bookings?' + debouncedQuery, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        if (response.data.status === 'SUCCESS') {
          setData(response.data.data);
          setTotalCount(response.data.total);
          setContentLoading(false);
          setLoading(false);
        } else {
          setContentLoading(false);
          setLoading(false);
        }
      }).catch(error => {
        setContentLoading(false);
        setLoading(false);
      });
    }
  }, [debouncedQuery, auth?.api_token]);

  const downloadCSV = React.useCallback(() => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/bookings?page=0&perPage=' + totalCount + '&search=' + filterText + '&start=' + filterData?.start_date + '&end=' + filterData?.end_date + '&status=' + filterData?.status, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        let allData = response.data.data;
        let newArray = allData.map(row => {
          return { 'booking_id': row.reference_id, 'customer_name': row.customer_name, 'order_date': moment(row.created_at).format('DD/MM/YYYY LT'), 'total_value': '£' + parseFloat(row.total).toFixed(2), 'paid_amount': '£' + parseFloat(row.paid_amount).toFixed(2), 'order_status': (row?.status === -1 || row?.status === '-1') ? 'Cancel' : ((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid' : 'Unpaid'), 'pickup_post_code': row.post_code, 'pickup_date': moment(row.pickup_date).format('DD/MM/YYYY'), 'created_user': row.created_user, 'registered_or_guest_user': row.created_user_type, 'complaint_status': 'No Complaints' }
        });
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(newArray);
        if (csv == null) return;

        const filename = 'booking-list.csv';

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }, [auth?.api_token, filterData?.end_date, filterData?.start_date, filterData?.status, filterText, totalCount]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const sendMailBooking = (id) => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/send-booking-pdf/' + id, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{ width: 'auto' }}>Export To CSV</Button>;

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{ width: 'auto' }}>Print Bookings</Button></>, [downloadCSV, handlePrint]);

  return (<>
    {loading && <Loader />}
    {contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Booking List</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Typography color="text.primary">Bookings</Typography>
          <Typography color="text.primary">List</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className='TableFilterHead'>
          <div className="DataTableSearch">
            <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={filterText} onChange={(e) => setFilterText(e.target.value)} autoComplete='off' />
            {filterText && <Button type="button" className="CloseBU" onClick={(e) => setFilterText('')}><X /></Button>}
          </div>
          <div style={{ width: '278px', marginLeft: '16px' }}>
            <DateRangePicker
              showOneCalendar
              format="dd/MM/yyyy"
              placeholder="Search by date"
              ranges={[]}
              onChange={(e) => changeDate(e)}
            />
          </div>
          <div className='TableFilterBUSec'>
            <FilterMenu filterBooking={(e) => filterBooking(e)} />
          </div>
        </div>
        <div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: '#FFFFFF', margin: '0 5px 0 15px', border: '#000 1px solid' }}></span><span>Booked - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(255,0,0,0.7)', margin: '0 5px 0 15px' }}></span><span>Cancelled - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(230, 79, 34, 0.1)', margin: '0 5px 0 15px' }}></span><span>Assigned To Driver - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(230, 79, 34, 0.4)', margin: '0 5px 0 15px' }}></span><span>Picked Up - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(230, 79, 34, 0.7)', margin: '0 5px 0 15px' }}></span><span>In Warehouse - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(42, 146, 245, 0.4)', margin: '0 5px 0 15px' }}></span><span>Shipped From Warehouse - UK</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(42, 146, 245, 0.5)', margin: '0 5px 0 15px' }}></span><span>Received In Warehouse - Ghana</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(5, 156, 42, 0.5)', margin: '0 5px 0 15px' }}></span><span>Out For Delivery - Ghana</span></div>
          <div style={{ float: 'left' }}><span style={{ display: 'inline-block', width: '15px', height: '15px', background: 'rgba(5, 156, 42, 0.9)', margin: '0 5px 0 15px' }}></span><span>Delivered - Ghana</span></div>
        </div>

        <div className='TableContent'>
          <div style={{ display: "none" }}><ComponentToPrintList ref={componentRef} totalCount={totalCount} filterText={filterText} start={filterData?.start_date} end={filterData?.end_date} status={filterData?.status} /></div>
          <DataTable className='DataTable2'
            columns={columns}
            data={data}
            customStyles={customDataTableStyles}
            conditionalRowStyles={conditionalRowStyles}
            fixedHeader={true}
            fixedHeaderScrollHeight={'633px'}
            actions={actionsMemo}
          />
          <table aria-label="custom pagination table" style={{ position: 'relative', display: 'block', background: '#fff', width: '100 %', height: '40px' }}>
            <tfoot>
              <tr>
                <CustomTablePagination
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page}
                  onPageChange={(e, p) => setPage(p)}
                  onRowsPerPageChange={(e) => setPerPage(e.target.value)}
                  slotProps={{
                    select: {
                      'aria-label': 'Rows per page',
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                      slots: {
                        firstPageIcon: FirstPageRoundedIcon,
                        lastPageIcon: LastPageRoundedIcon,
                        nextPageIcon: ChevronRightRoundedIcon,
                        backPageIcon: ChevronLeftRoundedIcon,
                      },
                    },
                  }}
                />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>}

    <Modal show={modalShow} onHide={(e) => setModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CancelPopup modalClose={(e) => setModalShow(false)} camcelItem={camcelItem.bind(this)} bookingId={currentItem} />
      </Modal.Body>
    </Modal>

    <Modal size="lg" show={paymentModalShow} onHide={(e) => setPaymentModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentPopup modalClose={(e) => setPaymentModalShow(false)} paymentItem={paymentItem.bind(this)} bookingId={currentItem} />
      </Modal.Body>
    </Modal>

    <Modal show={paymentModalShow2} onHide={(e) => setPaymentModalShow2(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentCancelPopup modalClose={(e) => setPaymentModalShow2(false)} paymentItem={paymentItem2.bind(this)} bookingId={currentItem} />
      </Modal.Body>
    </Modal>

    <Modal size="lg" show={detailsModalShow} onHide={(e) => setDetailsModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Order Information: #{currentItemStr}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {(currentItem > 0) && <DetailsPopup bookingId={currentItem} />}

      </Modal.Body>
    </Modal>

    <Modal show={deleteModalShow} onHide={(e) => setDeleteModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Delete Booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${styles.FullDiv}`}>
          <p className={`${styles.PopupTitle}`}>Are you sure to delete this booking?</p>
          <div className={`${styles.FormButtonArea}`}>
            <input className={`${styles.CancelBU}`} value="No" type="button" onClick={(e) => setDeleteModalShow(false)} />
            <input className={`${styles.SubmitBU}`} value="Yes" type="button" onClick={(e) => deleteBooking()} />
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal size="lg" show={addComplaintModalShow} onHide={(e) => setAddComplaintModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Add Complaint</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <AddComplaintPopup setAddComplaintModalShow={setAddComplaintModalShow.bind(this)} bookingData={addComplaintData} addNewComplaint={addNewComplaint.bind(this)} />

      </Modal.Body>
    </Modal>

    <Modal size="lg" show={viewComplaintModalShow} onHide={(e) => setViewComplaintModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>View Complaint</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <ViewComplaintPopup setViewComplaintModalShow={setViewComplaintModalShow.bind(this)} bookingId={addComplaintData?.id} addNewComplaint={addNewComplaint.bind(this)} />

      </Modal.Body>
    </Modal>

  </>);
}

export default withAdminAuth(BookingList);